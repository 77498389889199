import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
//
import './index.scss';
import motorcycleUtils from '../utils/motorcycles';
//
import { Layout, SEO } from '../components';
import BikeCard from '../components/BikeCard';
import SingleBikeSlider from '../components/Sliders/SingleBikeSlider';
import { BikeReview } from '../components/BikeReview';

const useFixedLogo = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "jpsstore-logo" } }) {
        edges {
          node {
            name
            image: childImageSharp {
              fixed(width: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `);

  const logo = data?.allFile?.edges?.[0]?.node || null;
  const logoFixedImage = logo?.image?.fixed || null;

  return logoFixedImage;
};

const SingleBike = pageData => {
  const { pageContext: node } = pageData;

  const imagesArray = node.images;
  const allImages = [node.featuredImage, ...imagesArray];
  // check allImages first
  const { slug } = node;
  const year = node.year || '';
  const vin = node.vin || '';
  const title = node.title || '';
  const engine = node.engine || '';
  const cylinder = node.cylinders || '';
  const transmission = node.transmission || '';
  const mileage = node.mileage || '';
  const color = node.color.name || '';
  const price = node.price || '';
  const status = node.status || '';
  const description = node.description || '';
  const hasLogo = node.hasLogo || null;

  // flatten graphql nodes and map for bide card component
  const relatedMotorcycles = motorcycleUtils
    .flattenGraphqlNodes(node.relatedMotorcycles)
    .map(motorcycleUtils.mapMotorcycleProps);

  const metadata = node.metadata || {
    title,
    description,
    image: node.featuredImage.image,
    keywords: 'motorcycles for sale, todo:',
  };
  const logoFixedImage = useFixedLogo();

  return (
    <Layout>
      <SEO path={`/motorcycles/${slug.current}`} {...metadata} />
      <section className="single-bike">
        <div className="container">
          <div className="section-header">
            <h1 className="section-title n-b-m n-t-m text-black">{title}</h1>
            <ul className="bread-crumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current-bread-crumb">{title}</li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="container">
          <div className="section-main">
            <div className="single-content">
              <div className="single-content-slider">
                <SingleBikeSlider allImages={allImages} hasLogo={hasLogo} logoFixedImage={logoFixedImage} />
                <div className="spec-table">
                  <div className="table-header">
                    <p>
                      <strong>Key Features</strong>
                      {title}
                    </p>
                  </div>
                  <div className="table-content">
                    <table>
                      <tbody>
                        <tr>
                          <td>VIN</td>
                          <td>
                            <strong>{vin}</strong>
                          </td>
                          <td>Year</td>
                          <td>
                            <strong>{year}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Model</td>
                          <td itemProp="name">
                            <strong>{title}</strong>
                          </td>
                          <td>Engine</td>
                          <td>
                            <strong>
{engine} cc</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Cylinders</td>
                          <td>
                            <strong>{cylinder}</strong>
                          </td>
                          <td>Transmission</td>
                          <td>
                            <strong>{transmission}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Mileage</td>
                          <td>
                            <strong>{mileage}</strong>
                          </td>
                          <td>Color</td>
                          <td itemProp="color">
                            <strong>{color}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>New/Used</td>
                          <td>
                            <strong>{status}</strong>
                          </td>
                          <td>Price</td>
                          <td>
                            <strong>
$
{price}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="bike-description">
                  <h3 className="model text-black n-t-m n-b-m">{title}</h3>
                  <p>{description}</p>
                </div>
                {relatedMotorcycles.length ? (
                  <div className="single-related-products text-center">
                    <h4 className="text-black bold">Related Products</h4>
                    <hr className="hr-reviews" />
                    <div className="motorcycles-grid">
                      {relatedMotorcycles.map(motorcycle => {
                        return <BikeCard {...motorcycle} logoFixedImage={logoFixedImage} />;
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <BikeReview
              text="JP Superbikes is top notch! I have been using this shop for at least 4 years. I just bought a new 2015
                  Can Am Spyder RT-S and pray that JP will be able to service my new machine the same way they handled
                  my Suzuki &amp; Kawasaki bikes. You know JZp is top quality when ever large shops like Destination
                  Power Sports in Punta Gorda even recommends them"
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SingleBike;
